import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import iconF1 from "../assets/image/svg/database.svg";
import iconF2 from "../assets/image/svg/no-data.svg";
import iconF3 from "../assets/image/svg/multilingual.svg";
import iconF4 from "../assets/image/svg/self-improving.svg";
import iconF6 from "../assets/image/svg/custom.svg";
import iconF5 from "../assets/image/svg/loop.svg";

const PFMFeatures = () => {
  return (
    <div className="feature-section pb-4 pb-lg-13 " style={{ paddingTop: "" }}>
      <Container>
        <Row className="justify-content-center">
          
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <div className="icon-wrap">
                  <img src={iconF1} alt="machine learning based" />
                </div>
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Source Agnostics</h5>
                  <p className="gr-text-9 white">
                    Enrich transactions from any source, including open banking.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF3} alt="multi lingual" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Multi-Lingual</h5>
                  <p className="gr-text-9 white">
                    Enrich transactions in English, العربية, русский, 中文 or any other language.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF5} alt="user feedback logo" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">User Feedback Loop</h5>
                  <p className="gr-text-9 white">
                  Allow your users to make corrections so Genify’s engine learns from them.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1100"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF2} alt="low to no data requirements" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">
                    Low-to-No Data Requirements
                  </h5>
                  <p className="gr-text-9 white">
                    Ready to use with your data on day 1 thanks to pre-trained
                    models.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF3} alt="real time" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Real-Time</h5>
                  <p className="gr-text-9 white">
                    Notify users of changes to their spending habits and offer
                    tips in real time
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1700"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF6} alt="customizable" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Customizable </h5>
                  <p className="gr-text-9 white">
                    Customize our engine to encompass notification trigger rules
                    of your own{" "}
                  </p>
                </div>
              </div>
            </div>
          </Col> */}
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF4} alt="smart suggestions" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Smart suggestions</h5>
                  <p className="gr-text-9 white">
                    Spending limits and savings goals are suggested to each user
                    individually based on their patterns and their peer group
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <div className="icon-wrap">
                  <img src={iconF1} alt="machine learning based" />
                </div>
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">
                    Machine Learning-Based
                  </h5>
                  <p className="gr-text-9 white">
                    Running with multiple machine learning models at its core.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PFMFeatures;
